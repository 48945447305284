<template>
  <div>
    <div
      v-if="productOffer"
      class="flex items-center my-2"
    >
      <!-- <img
        :src="require(`~/assets/images/pictos/${productOffer.picto}${selectOffer ? '-select' : ''}.svg`)"
        class="h-12 cursor-pointer mr-2"
      > -->
      <div class="flex items-center ml-1">
        <b-form-checkbox
          v-model="selectOffer"
          name="is-vertical-menu-collapsed"
          class="mr-0"
          switch
          inline
          @change="$emit('change', selectOffer ? productOffer : null)"
        >
          {{ productOffer.label }}
        </b-form-checkbox>
        <!-- <Checkbox
          v-model="selectOffer"
          class="font-action"
          text-size="sm"
          :label="productOffer.label"
          @input="$emit('change', selectOffer ? productOffer : null)"
        /> -->
        <!-- <img
          :src="require(`~/assets/images/pictos/interrogation.svg`)"
          class="h-4 cursor-pointer ml-1"
          @click="openModal = !openModal"
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectOffer: false,
      openModal: false,
      products: [
        // {
        //   productId: ['753', '1997', '1884', '1004', '101186'],
        //   picto: 'doubleetageres',
        //   label: 'Ajouter deux étagère en bois massif (+149€)',
        //   image: 'https://kipli.com/fr/wp-content/uploads/2020/08/etagere-murale-1.jpg',
        //   title: 'Etagère murale bois',
        //   description: 'L’étagère murale bois est fabriquée à partir de lamelles de hêtre massif français certifié PEFC. Notre étagère fixation invisible est produite en France dans nos ateliers picards et s’accordera très bien avec notre gamme en bois massif Kipli.',
        //   certifications: ['france', 'pefc'],
        //   price: 119,
        //   data: {
        //     productId: 80011, variationId: 80067, quantity: 1, url: 'etagere-murale-bois', offer: 'commode',
        //   },
        // },
        {
          productId: ['165', '714'],
          picto: '2-oreillers',
          label: 'Ajouter deux oreillers 100% naturel en Tencel® (+128€, économisez 30€)',
          image: 'https://res.cloudinary.com/kipli-cdn/image/upload/site/product/oreiller-tencel-generique/product-page/1',
          title: 'Oreiller 100% naturel en Tencel®',
          description: 'L\'oreiller dont vous n\'osiez plus rêver. Accueil moelleux et maintien confortable, grâce à une composition 100% naturelle.<br>- Garnissage en fibre issue de pulpes de bois, le Tencel®<br>- Housse en coton biologique<br>- Aucun traitement chimique, naturellement anti-acariens et anti-bactérien<br>- Fabrication française',
          certifications: ['tencel'],
          price: 64,
          data: {
            productId: 129882, variationId: 142911, quantity: 2, url: 'oreiller-naturel-tencel', offer: '2oreiller30',
          },
        },
        {
          productId: ['96057', '714'],
          picto: '2-oreillers',
          label: 'Ajouter deux oreillers en latex naturel (+168€, économisez 30€)',
          video: 'https://s3.eu-west-3.amazonaws.com/cdn.kipli/video/grid/oreiller-saponetta.mp4',
          title: 'Oreiller en latex naturel',
          description: 'L’oreiller latex naturel Kipli est fabriqué à la main en Italie à partir du même latex 100% naturel que le matelas. Il offre un confort ergonomique grâce aux propriétés viscoélastiques du latex. Sa housse en coton bio et Tencel, une fibre issue de pulpe de bois, procure douceur et respirabilité.',
          certifications: ['eurolatex', 'oekotex'],
          price: 89,
          data: {
            productId: 1332, variationId: 1332, quantity: 2, url: 'oreiller-latex-naturel', offer: '2oreiller30',
          },
        },
        {
          productId: ['104264'],
          picto: '2-oreillers',
          label: 'Ajouter deux oreillers plumes (+168€, économisez 30€)',
          video: 'https://s3.eu-west-3.amazonaws.com/cdn.kipli/video/grid/oreiller-plume.mp4',
          title: 'Oreiller en duvet et plumes',
          description: 'L’oreiller en plumes Kipli est le fruit d’un savoir-faire français d’excellence qui assure un confort de grande qualité. Fabriqué dans une usine française centenaire, son duvet et ses plumettes apportent un gonflant et une légèreté incomparables. Son enveloppe en percale de coton biologique vous garantit un toucher résistant, doux et sain. Un véritable nuage enveloppant pour accompagner vos nuits.',
          certifications: ['gots', 'epv'],
          price: 89,
          data: {
            productId: 104250, variationId: 104250, quantity: 2, url: 'oreiller-en-plumes-et-duvet', offer: '2oreiller30',
          },
        },
        {
          productId: ['1626'],
          picto: 'doubletablechevet',
          label: 'Ajouter deux tables de chevet (+508€, économisez 50€)',
          video: 'https://s3.eu-west-3.amazonaws.com/cdn.kipli/video/grid/table-de-chevet.mp4',
          title: 'Table de chevet en bois massif',
          description: 'La table de chevet bois massif Kipli est fabriquée à partir de lamelles de hêtre massif français certifié PEFC. Elle est fabriquée en Picardie et se mariera parfaitement avec le sommier Picardie et la commode en bois massif Kipli.',
          certifications: ['france', 'pefc'],
          price: 254,
          data: {
            productId: 1997, variationId: 2917, quantity: 2, url: 'table-de-chevet', offer: '2tablechevet50',
          },
        },
        {
          productId: ['1338'],
          picto: 'dressing',
          label: 'Ajouter deux tables de chevet (+508€, économisez 50€)',
          video: 'https://s3.eu-west-3.amazonaws.com/cdn.kipli/video/grid/table-de-chevet.mp4',
          title: 'Table de chevet en bois massif',
          description: 'La table de chevet bois massif Kipli est fabriquée à partir de lamelles de hêtre massif français certifié PEFC. Elle est fabriquée en Picardie et se mariera parfaitement avec le sommier Picardie et la commode en bois massif Kipli.',
          certifications: ['france', 'pefc'],
          price: 254,
          data: {
            productId: 1997, variationId: 2917, quantity: 2, url: 'table-de-chevet', offer: '2tablechevet50',
          },
        },
        {
          country: 'FR',
          productId: ['123638'],
          picto: 'chevet2',
          label: 'Ajouter deux tables de chevet murale Livia (+740€, économisez 100€)',
          image: 'https://res.cloudinary.com/kipli-cdn/image/upload/site/product/chevet-murale-livia/product-page/cchevetniche/2',
          title: 'Table de chevet en bois massif',
          description: 'Avec ses lignes épurées, la table de chevet murale en chêne massif LIVIA apportera une touche d\'élégance à votre chambre. Fabriquée en Haute-Marne par des artisans au savoir-faire précieux, ses finitions sont réalisées à la main pour une pièce irréprochable. <br> Modulable, elle vous permet d\'ajouter un tiroir en option selon vos envies.<br><br>- Bois de chêne massif PEFC et labellisé Origine France<br>- Fabrication 100% française en Haute-Marne<br>- Aucune colle ni traitement chimique <br>- Vernis à base d\'eau, sans COV',
          certifications: ['france', 'pefc'],
          price: 370,
          data: {
            productId: 123644, variationId: 123645, quantity: 2, url: 'table-de-chevet-murale-bois-massif-livia', offer: '2tablechevet100',
          },
        },
      ],
      productOffer: null,
    }
  },
  mounted() {
    console.log(this.product)
    this.productOffer = this.products?.find(p => p.productId.includes(this.product.id?.toString()))
  },
}
</script>
